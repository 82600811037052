<template>
    <div class="box" id="pdf">
        <div class="page_head">
            <div class="head_left">
                <img src="@/images/ansulogo.png" style="width:150px;margin-top: 15px;">
                <div style="float:right; margin-top: 17px;">
                    <h1>
                        <span>安</span>
                        <span>速</span>
                        <span>保</span>
                        <span>险</span>
                    </h1>
                    <h1 style="color:red">ANSU INSURANCE</h1>
                </div>
            </div>
            <div class="head_right" style="width:50%">
                <p> 地址:深圳市龙岗区平湖街道富安大道海源国际金融中心T1栋708</p>
                <p>邮编:518000</p>
                <p>Address:708, building T1, Haiyuan international financial center, Fu'an Avenue, Pinghu street,
                    Longgang District, Shenzhen</p>
            </div>
        </div>
        <div class="page_content">
            <div class="page_title">
                <h1 style="font-size:20px;font-weight: 500;">货 物 运 输 货 物 保 险 单</h1>
                <h1 style="font-size:10px;font-weight: 500;">CARGO TRANSPORTATION INSURANCE POLICY</h1>
            </div>
            <div class="inv_msg">
                <span>被保险人(Insured): {{row.name}}</span>
                <span>保险单号(Policy No.):{{row.safe_no}}</span>
                <span style="margin-top:15px">地址/电话/( Address /Tel):</span>
            </div>
            <div class="page_explain" style="margin-top:30px">
                <p>深圳市安速国际货运代理有限公司保险部（以下简称 “本公司”）根据被保险人要求，以被保险人向本公司缴付约定的保险费后，按照本保险单列明条款承保货物运输保险，特订立本保险单。</p>
                <p style="line-height:15px;font-size: 13px;">This Policy of Insurance witnesses that Shenzhen Ansu
                    International Freight Forwarding Co., Ltd（hereinafter called “the company”）at the request of the
                    insured and in consideration of the agreed premium paid to the company by the insured, undertakes to
                    insured the undermentioned goods in transportation subject to the conditions of this Policy as per
                    the clauses printed below.</p>
            </div>
            <div class="page_table">
                <table border="1" style="border-collapse: collapse;">
                    <tr>
                        <th>唛头或标记
                            <br>Shipping Mark</th>
                        <th>被保险货物名称<br>Description</th>
                        <th>被保险货物数量及包装<br>Quantity & Packing of Goods</th>
                        <th> 保险金额<br>Sum Insured</th>
                    </tr>
                    <tr>
                        <td style="height:100px"> SEE ATTACHMENT</td>
                        <td>{{getGoods(details.cargoes)}}</td>
                        <td>{{details.cargoes_num}}纸箱</td>
                        <td>{{"RMB"+row.safe_amount}}</td>
                    </tr>
                </table>
            </div>
            <div class="main_msg">
                <span>总保险金额(Sum Insured In Words):<span class="underline" style="width: 350px;">人民币&nbsp;&nbsp;{{getMsg(row.safe_amount)}}&nbsp;&nbsp;
                        {{"("+"RMB"+row.safe_amount+")"}}</span></span>
                <span>保费(Premium In Words):<span class="underline" style="width: 350px;">AS ARRANGED</span></span>
                <span>运单号（B/L No,）:<span class="underline" style="width: 350px;">{{details.waybill_no}}</span> </span>
                <span>运输工具名称/航次号(Per Conveyance/Voy.):<span class="underline" style="width: 350px;"></span></span>
                <span>起运日期(Sailing On or Abt.):<span class="underline" style="width: 350px;">
                        <!-- {{ row.create_time > 0 ?moment(row.create_time*1000).format("YYYY/MM/DD"):""}} -->
                    </span> </span>
                <span>
                    运输路线(Voyage)：自
                    <br>
                    <table>
                        <tr>
                            <td style="height:100px;width:10%">(From):</td>
                            <td style="width:20%;border-bottom: 1px solid;">SHENZEHN(CHN)</td>
                            <td style="height:100px;width:10%">(Via):经至</td>
                            <td style="width:20%;border-bottom: 1px solid;"></td>
                            <td style="height:100px;width:8%">(To):</td>
                            <td style="border: 1px solid;">{{row.address}}</td>
                        </tr>
                    </table>
                </span>
            </div>
            <div style="width:92%;margin:0 auto; margin-top:30px;">
                保险条款、特约条款及保证条款(Clauses, Special Conditions And Warranties):
                SEE ATTACHMENT
            </div>
            <img src="@/images/yz.png" style="
            width: 164px;
    position: absolute;
    left: 62%;
    top: 63%;">
        </div>
        <div class="page_foot">
            <p> 深圳市龙岗区平湖街道海源国际金融中心T1栋708</p>
            <p>制单人(Made by):19501270</p>
            <p style=" display:inline-block;margin-left:45px">The Policy is void without authorized signature
                。没有授权人签字保单无效。<br>
                Security Code (安全代码):</p>
        </div>
    </div>
</template>

<script>
    import html2Canvas from 'html2canvas'
    import JsPDF from 'jspdf'
    import moment from 'moment'
    import {
        safeInfo
    } from "@/api/Waybill"
    export default {
        name: "PDF",
        props: {
            details: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                moment,
                row: {}
            }
        },
        created() {
            console.log(this.details);
            safeInfo({
                waybillId: Number(this.$route.query.id)
            }).then(res => {
                this.row = res.data
                if (res.msg == '未购买保险') {
                    this.$message.error('未购买保险')
                }
            })
        },
        methods: {
            getGoods(val){
                let arr = []
                for(let i of val){
                    for(let j of i.items){
                        if(arr.indexOf(j.en_item_name)==-1){
                            arr.push(j.en_item_name)
                        }
                    }
                }
                return arr.join(',')
            },
            submit() {
                const domElement = document.getElementById('pdf')
                html2Canvas(domElement, {
                    allowTaint: true,
                    taintTest: false,
                    useCORS: true,
                    dpi: window.devicePixelRatio * 4, //将分辨率提高到特定的DPI 提高四倍
                    scale: 4
                }).then(canvas => {
                    const contentWidth = canvas.width
                    const contentHeight = canvas.height
                    // 一页pdf显示html页面生成的canvas高度;
                    const pageHeight = (contentWidth / 592.28) * 841.89
                    // 未生成pdf的html页面高度
                    let leftHeight = contentHeight
                    // 页面偏移
                    let position = 0
                    // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
                    const imgWidth = 555.28
                    const imgHeight = (552.28 / contentWidth) * contentHeight
                    const pageData = canvas.toDataURL('image/jpeg', 1.0)
                    const pdf = new JsPDF('', 'px', [595.28, 841.89])
                    // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
                    // 当内容未超过pdf一页显示的范围，无需分页
                    if (leftHeight < pageHeight) {
                        pdf.addImage(pageData, 'JPEG', 20, 0, imgWidth, imgHeight)
                    } else {
                        while (leftHeight > 0) {
                            pdf.addImage(pageData, 'JPEG', 20, position, imgWidth, imgHeight)
                            leftHeight -= pageHeight
                            position -= 841.89
                            // 避免添加空白页
                            if (leftHeight > 0) {
                                pdf.addPage()
                            }
                        }
                    }
                    pdf.save('content.pdf')
                })
            },
            getMsg(number, type = "upper") {
                const confs = {
                    lower: {
                        num: ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"],
                        unit: ["", "十", "百", "千", "万"],
                        level: ["", "万", "亿"]
                    },
                    upper: {
                        num: ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"],
                        unit: ["", "拾", "佰", "仟"],
                        level: ["", "万", "亿"]
                    },
                    decimal: {
                        unit: ["分", "角"]
                    },
                    maxNumber: 999999999999.99
                };

                // 过滤不合法参数
                if (Number(number) > confs.maxNumber) {
                    console.error(
                        `The maxNumber is ${confs.maxNumber}. ${number} is bigger than it!`
                    );
                    return false;
                }

                const conf = confs[type];
                const numbers = String(Number(number).toFixed(2)).split(".");
                const integer = numbers[0].split("");
                const decimal = Number(numbers[1]) === 0 ? [] : numbers[1].split("");

                // 四位分级
                const levels = integer.reverse().reduce((pre, item, idx) => {
                    let level = pre[0] && pre[0].length < 4 ? pre[0] : [];
                    let value =
                        item === "0" ? conf.num[item] : conf.num[item] + conf.unit[idx % 4];
                    level.unshift(value);

                    if (level.length === 1) {
                        pre.unshift(level);
                    } else {
                        pre[0] = level;
                    }

                    return pre;
                }, []);

                // 整数部分
                const _integer = levels.reduce((pre, item, idx) => {
                    let _level = conf.level[levels.length - idx - 1];
                    let _item = item.join("").replace(/(零)\1+/g, "$1"); // 连续多个零字的部分设置为单个零字

                    // 如果这一级只有一个零字，则去掉这级
                    if (_item === "零") {
                        _item = "";
                        _level = "";

                        // 否则如果末尾为零字，则去掉这个零字
                    } else if (_item[_item.length - 1] === "零") {
                        _item = _item.slice(0, _item.length - 1);
                    }

                    return pre + _item + _level;
                }, "");

                // 小数部分
                let _decimal = decimal
                    .map((item, idx) => {
                        const unit = confs.decimal.unit;
                        const _unit = item !== "0" ? unit[unit.length - idx - 1] : "";

                        return `${conf.num[item]}${_unit}`;
                    })
                    .join("");

                // 如果是整数，则补个整字
                return `${_integer}元` + (_decimal || "整");
            }
        }
    }
</script>

<style lang="less" scoped>
    .box {
        position: relative;
        width: 794px;
        height: 1123px;
        color: black;
    }

    .page_head {
        display: flex;
        justify-content: space-between;

        .head_right {
            p {
                font-size: 10px;
            }
        }

        .head_left {
            div {
                h1 {
                    font-size: 16px;
                    display: flex;
                    margin-top: 6px;
                    margin-left: 10px;
                    justify-content: space-between;
                    // width: 200px;
                }
            }

        }
    }

    .page_content {
        // display: flex;   
        width: 100%;

        .page_title {
            margin-top: 15px;
            text-align: center;
            width: 100%;
        }

        .inv_msg {
            width: 90%;
            display: flex;
            flex-wrap: wrap;
            margin: 0 auto;
            margin-top: 30px;

            span {
                width: 50%
            }
        }

        .page_explain {
            width: 96%;
            margin: 0 auto;

        }

        .page_table {
            width: 96%;
            margin: 0 auto;

            table {
                width: 100%
            }
        }

        .main_msg {

            width: 90%;
            margin: 0 auto;
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;

            span {
                width: 100%;
                margin-top: 10px;
                font-size: 12px;

                table {
                    width: 100%
                }
            }
        }
    }

    .underline {
        display: inline-block;
        border-bottom: 1px solid;
        text-align: center;
    }

    .page_foot {
        position: absolute;
        bottom: 30px;
        left: 50px;
    }
</style>