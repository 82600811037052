<!--
 * @Author: your name
 * @Date: 2021-11-30 10:40:33
 * @LastEditTime: 2021-12-30 10:50:40
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Waybill/_details/index.vue
-->
<template>
  <div>
    <TabHeader title="运单详情"></TabHeader>
    <div class="fd-outer">
      <a-row>
        <a-col :span="8">
          <span class="fd-s1">运单编号：{{ form.waybill_no }}</span>
        </a-col>
        <a-col :span="8">
          <span class="fd-s2"
            ><span class="s1">下单时间：</span
            ><span class="s2">{{ form.created_at | timeStr }}</span></span
          >
        </a-col>
        <a-col :span="8">
          <span class="fd-s2" v-if="!form.has_invoice"
            ><span class="s1">发票：</span
            ><span class="s2" style="color: #ff0000">未做发票</span></span
          >
          <span class="fd-s2" v-else
            ><span class="s1">发票：</span
            ><span class="s2">已做发票</span></span
          >
        </a-col>
      </a-row>
      <a-row style="margin-top: 20px">
        <a-col :span="8">
          <div class="fd-address">
            <div class="d1">
              <div class="s1">起运点</div>
              <div class="s2">{{ form.original }}</div>
            </div>
            <div class="d2">
              <img src="../../../../../assets/images/111.png" />
            </div>
            <div class="d1">
              <div class="s1">目的国</div>
              <div class="s2">{{ form.destination }}</div>
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <span class="fd-s2"
            ><span class="s1">运单状态：</span
            ><span class="s2"> {{ form.status_name }}</span></span
          >
        </a-col>
        <a-col :span="8">
          <span class="fd-s2"
            ><span class="s1">转单号：</span
            ><span class="s2">{{ form.transship_no | timeStr }}</span></span
          >
        </a-col>
      </a-row>
      <div class="fd-line"></div>
      <div class="fd-inner">
        <div class="fd-inner-title">报关信息</div>
        <a-row style="margin-top: 20px">
          <a-col :span="8">
            <span class="fd-s2">
              <span class="s1">VAT税号：</span>
              <span class="s2">{{ form.vat }}</span>
              <!-- <a-popover placement="bottom" trigger="click">
                                <template slot="content">
                                    <a-input placeholder="请输入">
                                        <span class="b-atn" slot="addonAfter">确定</span>
                                    </a-input>
                                </template>
                                <template slot="title">
                                    <span>VAT税号</span>
                                </template>
                                <a-button type="link">输入</a-button>
                            </a-popover> -->
            </span>
          </a-col>
          <a-col :span="8">
            <span class="fd-s2">
              <span class="s1">贸易类型：</span>
              <span class="s2">{{ form.trade_type | tradeTypeStr }}</span>
            </span>
          </a-col>
          <a-col :span="8">
            <span class="fd-s2">
              <span class="s1">交税方式：</span>
              <span class="s2">{{ form.is_deferred | isDeferredStr }}</span>
            </span>
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col :span="8">
            <span class="fd-s2">
              <span class="s1">单独清关：</span>
              <span class="s2">{{
                form.is_separate_customs_clearance | isSeparateCustomsClearance
              }}</span>
            </span>
          </a-col>
          <a-col :span="8">
            <span class="fd-s2">
              <span class="s1">Amazon Reference ID：</span>
              <span class="s2">{{ form.amazon_reference_id }}</span>
            </span>
          </a-col>
        </a-row>
      </div>
      <div class="fd-line"></div>
      <div class="fd-inner" v-if="form.type === 2">
        <div class="fd-inner-title">运单信息</div>
        <a-row style="margin-top: 20px">
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">目的国：</span
              ><span class="s2">{{ form.address.country }}</span></span
            >
          </a-col>
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">收件人：</span
              ><span class="s2">{{ form.address.name }}</span></span
            >
          </a-col>
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">收件人电话：</span
              ><span class="s2">{{ form.address.phone }}</span></span
            >
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">收货人邮箱：</span
              ><span class="s2">{{ form.address.email }}</span></span
            >
          </a-col>
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">收件邮编：</span
              ><span class="s2">{{ form.address.zip_code }}</span></span
            >
          </a-col>
        </a-row>
      </div>
      <div class="fd-inner" v-if="form.type === 1">
        <div class="fd-inner-title">运单信息</div>
        <a-row style="margin-top: 20px">
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">仓库名称：</span
              ><span class="s2">{{ form.address.name }}</span></span
            >
          </a-col>
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">仓库邮编：</span
              ><span class="s2">{{ form.address.zip_code }}</span></span
            >
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">仓库地址：</span
              ><span class="s2">{{ form.address.address }}</span></span
            >
          </a-col>
        </a-row>
      </div>
      <div class="fd-line"></div>
      <div class="fd-inner">
        <div class="fd-inner-title">产品信息</div>
        <a-row style="margin-top: 20px">
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">状态：</span
              ><span class="s2">{{ form.status_name }}</span></span
            >
          </a-col>
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">申报币种：</span
              ><span class="s2">{{ form.currency_name }}</span></span
            >
          </a-col>
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">申报价值：</span
              ><span class="s2">{{ form.declared_value }}</span></span
            >
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">下单时间：</span
              ><span class="s2">{{ form.created_at | timeStr }}</span></span
            >
          </a-col>
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">件数：</span
              ><span class="s2">{{ form.cargoes_num }}</span></span
            >
            <a-button
              type="link"
              v-if="this.status == 1 && this.form.has_invoice == 0"
              @click="eidtWaybill"
            >
              修改</a-button
            >
          </a-col>
        </a-row>
      </div>
      <!-- <div class="fd-line"></div>
            <div class="fd-inner">
                <div class="fd-inner-title">转单信息</div>
                <a-row style="margin-top: 20px">
                    <a-col :span="8">
                        <span class="fd-s2"><span class="s1">快递编号：</span><span class="s2">{{form.transship.transship_no}}</span></span>
                    </a-col>
                    <a-col :span="8">
                        <span class="fd-s2"><span class="s1">快递名称编码：</span><span class="s2">{{form.transship.transship_code}}</span></span>
                    </a-col>
                </a-row>
            </div> -->
      <div class="fd-line"></div>
      <div class="fd-inner">
        <div class="fd-inner-title">其他信息</div>
        <!-- <a-row style="margin-top: 20px">
                    <a-col :span="24">
                        <span class="fd-s2">
                            <span class="s1">有无保险：</span>
                            <span class="s2">{{ form.have_safe ? '有保险' : '无保险' }}</span>
                            <a-popconfirm v-if="!form.have_safe && ![2, 4, 6].includes(form.status)" ok-text="确定" cancel-text="取消" @confirm="handlerSafe(form)">
                                <template slot="title">
                                    <span>确定购买保险嘛？</span>
                                </template>
                                <a-button type="link">购买保险</a-button>
                            </a-popconfirm>
                            <a-popconfirm v-if="form.have_safe && ![2, 4, 6].includes(form.status)" ok-text="确定" cancel-text="取消" @confirm="handlerCancel(form)">
                                <template slot="title">
                                    <span>确定取消保险嘛？</span>
                                </template>
                                <a-button type="link">取消保险</a-button>
                            </a-popconfirm>
                        </span>
                    </a-col>
                </a-row> -->
        <a-row style="margin-top: 20px" v-if="form.have_safe == 1">
          <a-col :span="24">
            <span class="fd-s2"
              ><span class="s1">保单：</span
              ><span class="s2">
                <a-button type="link" @click="checkSafe">查看保单</a-button>
              </span></span
            >
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col :span="24">
            <span class="fd-s2"
              ><span class="s1">备注：</span
              ><span class="s2">{{ form.remark }}</span></span
            >
          </a-col>
        </a-row>
      </div>
      <div class="fd-line"></div>
      <div class="fd-inner">
        <div class="fd-inner-title">装箱信息（共{{ form.cargoes_num }}箱）</div>
        <div style="float: left">
          <a-button @click="openAll">{{ isOpen ? "收起" : "展开" }}</a-button>
        </div>
        <div class="fd-inner-bd" align="right">
          <span>申报币种：{{ form.currency_name }}</span>
          <span>总申报价值：{{ form.declared_value }}</span>
        </div>
        <a-table
          size="small"
          style="margin-top: 10px"
          bordered
          :pagination="pagination"
          :columns="columns"
          :expandedRowKeys.sync="expandedRowKeys"
          :data-source="tableData"
          :scroll="{ x: 'max-content' }"
          :rowKey="(record, index) => record.id"
        >
          <template slot="cargoNo" slot-scope="text, record">
            <span v-if="record.fba_no">{{ record.fba_no }}</span>
            <span v-else>{{ text.substring(text.length - 6) }}</span>
          </template>
          <template slot="expandedRowRender" slot-scope="record">
            <a-table
              size="small"
              :columns="innerColumns"
              :data-source="record.items"
              :scroll="{ x: 'max-content' }"
              :pagination="false"
              :rowKey="(record, index) => record.id"
            >
              <template slot="totalPrice" slot-scope="row">
                <span>{{ (row.num * row.declaredValue).toFixed(2) }}</span>
              </template>
              <template slot="photo" slot-scope="record">
                <a-popover placement="bottom">
                  <template slot="content">
                    <img
                      style="width: 200px"
                      :src="record.photo"
                      v-if="record.photo"
                    />
                  </template>
                  <template slot="title">
                    <span>图片</span>
                  </template>
                  <a class="btn-a" style="margin-left: 10px">查看</a>
                </a-popover>
              </template>
            </a-table>
          </template>
        </a-table>
      </div>
      <div class="fd-line"></div>
      <div class="fd-inner">
        <div class="fd-inner-title">
          材质:
          <a-checkbox-group
            disabled
            v-model="form.material_cates"
            :options="materials"
          >
          </a-checkbox-group>
        </div>
      </div>
      <div style="margin-top: 20px">
        <a-button
          style="margin-left: 15px"
          type="primary"
          ghost
          @click="makeInvoice(form)"
          v-if="!form.has_invoice"
        >
          制作发票
        </a-button>
      </div>
    </div>
    <a-drawer
      :title="drawerObj.title"
      placement="right"
      :width="drawerObj.width"
      :closable="false"
      :visible="drawerObj.visible"
      @close="onClose"
      :maskClosable="false"
    >
      <edit-count
        :infoData="form"
        v-if="drawerObj.index == 1"
        ref="editForm"
      ></edit-count>
      <PDF v-if="drawerObj.index == 2" ref="pdf" :details="form"></PDF>
      <div class="drawerFooter">
        <a-button-group>
          <a-button @click="handleNo"> 取消 </a-button>
          <a-button type="primary" @click="handleOk"> 确定 </a-button>
        </a-button-group>
      </div>
    </a-drawer>
    <a-modal
      :title="modalObj.title"
      :visible="modalObj.visible"
      :width="modalObj.width"
      @cancel="modalObj.visible = false"
      :maskClosable="false"
    >
      <template slot="footer">
        <a-button @click="handleCancel">取消</a-button>
        <a-button :loading="confirmLoading" type="primary" ghost @click="save"
          >保存</a-button
        >
        <a-button :loading="confirmLoading" type="primary" @click="submit"
          >确定</a-button
        >
      </template>
      <make
        ref="makeRef"
        :id="rowId"
        @success="handlerSuc"
        @fail="handlerFail"
        v-if="modalObj.visible"
      ></make>
    </a-modal>
  </div>
</template>

<script>
import { billInfo } from "@/api/Billing";
import { materialList } from "@/api/comm";
import {
  // waybillInfo,
  invoiceInfo,
  waybillUnsafe,
  waybillSafe,
  cargoItemAdd,
  cargoItemDel,
  cargoItemEdit,
} from "@/api/Waybill";
import { productList } from "@/api/comm";
import make from "../../../Bill/curd/make.vue";
import moment from "moment";
import columns from "./curd/columns";
import innerColumns from "./curd/innerColumns";
import editCount from "./curd/editCount.vue";
import PDF from "./curd/PDF.vue";
export default {
  name: "Waybill_details",
  components: {
    make,
    editCount,
    PDF,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      rowId: "",

      isOpen: false,
      expandedRowKeySource: [],
      expandedRowKeys: [],
      confirmLoading: false,
      columns: columns,
      innerColumns: innerColumns,
      tableData: [],
      materials: [],
      modalObj: {
        title: "",
        visible: false,
        width: "500px",
      },
      md: {
        title: "",
        visible: false,
        width: "460px",
      },
      drawerObj: {
        title: "",
        visible: false,
        width: "600px",
        index: "",
      },
      cargoeObj: {
        items: [],
      },
      chooseItem: {},
      itemType: "add",
      products: [],
      product: {
        cargoId: "",
        goodsId: "",
        goodsNum: "",
      },
      form: {
        waybill_no: "",
        type: "",
        has_invoice: "",
        original: "",
        destination: "",
        vat: "",
        trade_type: "",
        is_deferred: "",
        is_separate_customs_clearance: "",
        amazon_reference_id: "",
        declared_value: "",
        currency_name: "",
        status_name: "",
        status: "",
        created_at: "",
        remark: "",
        address: {
          country: "",
          name: "",
          zip_code: "",
          phone: "",
          email: "",
          address: "",
        },
        address_fba: {
          zip_code: "",
          name: "",
          address: "",
        },
        cargoes_num: "",
        cargoes: [],
        transship: {
          transship_code: "",
          transship_no: "",
        },
      },
      pagination: {
        defaultCurrent: 1,
        defaultPageSize: 10,
        showTotal: (total) => `共 ${total} 条`,
        showQuickJumper: true,
      },
    };
  },
  created() {
    this.getDetails();
    this.materialList();
    this.status = this.$route.query.status;
  },
  methods: {
    openAll() {
      if (this.isOpen) {
        this.expandedRowKeys = [];
      } else {
        this.expandedRowKeys = [...this.expandedRowKeySource];
      }
      this.isOpen = !this.isOpen;
    },
    handleNo() {
      this.drawerObj.visible = false;
    },

    checkSafe() {
      (this.drawerObj.index = 2), (this.drawerObj.visible = true);
      this.drawerObj.width = "850px";
      this.drawerObj.title = "查看保单";
    },
    handleOk() {
      if (this.drawerObj.index == 1) {
        this.$refs.editForm.submit().then(() => {
          this.getDetails();
          this.drawerObj.visible = false;
        });
      } else if (this.drawerObj.index == 2) {
        this.$refs.pdf.submit();
        this.drawerObj.visible = false;
      }
    },
    //修改货物
    eidtWaybill() {
      this.drawerObj.visible = true;
      this.drawerObj.title = "修改";
      this.drawerObj.index = 1;
    },
    goBack() {
      this.$router.go(-1);
    },
    // 获取材质
    materialList() {
      materialList().then((res) => {
        let list = res.data;
        const arr = [];
        list.forEach((ele) => {
          let obj = {
            label: ele.name,
            value: ele.id,
          };
          arr.push(obj);
        });
        this.materials = arr;
      });
    },
    // 获取运单详情
    getDetails() {
      return new Promise((resolve, reject) => {
        let id = this.id * 1;
        if (id) {
          let obj = {
            waybillId: id,
          };
          billInfo(obj)
            .then((res) => {
              this.form = res.data;
              this.tableTotalVal = this.form.declared_value;
              const cargoes = res.data.cargoes || [];
              const arr = [];
              cargoes.forEach((ele) => {
                ele.cargoId = ele.id;
                ele.cargoNo = ele.cargo_no;
                const items = ele.items || [];
                items.forEach((e) => {
                  e.productId = e.goods_id;
                  e.enItemName = e.en_item_name;
                  e.zhItemName = e.zh_item_name;
                  e.num = e.goods_num;
                  e.enMaterial = e.en_material;
                  e.zhMaterial = e.zh_material;
                  e.hsCode = e.hs_code;
                  e.enPurpose = e.en_purpose;
                  e.zhPurpose = e.zh_purpose;
                  e.declaredValue = e.declared;
                  if (e.photo) {
                    if (!e.photo.includes("http")) {
                      e.photo = process.env.VUE_APP_CDN + e.photo;
                    }
                  }
                  // e.photo = e.photo ? process.env.VUE_APP_CDN + e
                  //     .photo : "";
                  e.totalPrice = 0;
                  e.import = 0;
                });
                arr.push(ele);
              });
              this.tableData = arr;
              for (let i of this.tableData) {
                this.expandedRowKeySource.push(i.id);
              }
              this.isShow = true;
              resolve(true);
            })
            .catch(() => {
              reject(false);
            });
        } else {
          reject(false);
        }
      });
    },
    // 获取发票详情
    getInvoiceInfo() {
      let id = this.$route.query.id * 1;
      if (id) {
        invoiceInfo({
          waybillId: id,
        }).then((res) => {
          this.invoice = {
            ...res.data,
          };
        });
      }
    },
    // 查看货件
    openCargoes(item) {
      this.cargoeObj = item;
      this.drawerObj.title = "货件编号：" + item.cargo_no;
      this.drawerObj.visible = true;
    },
    onClose() {
      this.drawerObj.visible = false;
    },
    // 制作发票
    makeInvoice(row) {
      this.$router.push({
        path: "/Waybill/makeAnInvoice",
        query: {
          id: row.id,
        },
      });
    },
    // 关闭弹出框
    handleCancel() {
      this.modalObj.visible = false;
    },
    // 保存
    save() {
      this.confirmLoading = true;
      this.$refs.makeRef.save();
    },
    // 制作
    submit() {
      this.confirmLoading = true;
      this.$refs.makeRef.onSubmit();
    },
    handlerSuc() {
      this.confirmLoading = false;
      this.modalObj.visible = false;
      this.getDetails();
    },
    handlerFail() {
      this.confirmLoading = false;
    },
    // 购买保险
    handlerSafe(row) {
      waybillSafe({
        waybillId: row.id,
      }).then(() => {
        this.$message.success("购买成功");
        this.getDetails();
      });
    },
    // 取消保险
    handlerCancel(row) {
      waybillUnsafe({
        waybillId: row.id,
      }).then(() => {
        this.$message.success("取保成功");
        this.getDetails();
      });
    },
    // 添加商品
    addBtn(item) {
      this.itemType = "add";
      this.md.title = "添加商品";
      this.product.cargoId = item.id;
      this.product.goodsId = "";
      this.product.goodsNum = "";
      this.md.visible = true;
    },
    //搜索产品
    handleSearchProd(val) {
      return new Promise((resolve, reject) => {
        let obj = {
          goodsName: val,
          page: 1,
          limit: 60000,
        };
        productList(obj)
          .then((res) => {
            this.products = res.data.list;
            resolve(true);
          })
          .catch(() => {
            reject(false);
          });
      });
    },
    // 确定添加商品
    handlerOk() {
      this.$refs.productRef.validate((valid) => {
        if (valid) {
          if (this.itemType === "add") {
            cargoItemAdd(this.product).then(() => {
              this.$message.success("商品添加成功");
              this.md.visible = false;
              this.getDetails().then(() => {
                const ele = this.form.cargoes.find(
                  (item) => item.id === this.product.cargoId
                );
                this.cargoeObj = ele;
              });
            });
          } else {
            let obj = {
              cargoId: this.cargoeObj.id,
              cargoItemId: this.chooseItem.id,
              goodsNum: this.product.goodsNum,
            };
            cargoItemEdit(obj).then(() => {
              this.$message.success("商品修改成功");
              this.md.visible = false;
              this.chooseItem.goods_num = this.product.goodsNum;
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 编辑商品
    edit(row) {
      this.handleSearchProd("").then(() => {
        this.itemType = "edit";
        this.md.title = "编辑产品";
        this.md.visible = true;
        this.chooseItem = row;
        this.product.goodsId = row.goods_id;
        this.product.goodsNum = row.goods_num;
      });
    },
    // 删除商品
    del(id) {
      let obj = {
        cargoId: this.cargoeObj.id,
        cargoItemId: id,
      };
      cargoItemDel(obj).then(() => {
        this.$message.success("商品删除成功");
        this.md.visible = false;
        const dataSource = [...this.cargoeObj.items];
        this.cargoeObj.items = dataSource.filter((item) => item.id !== id);
        this.getDetails();
      });
    },
  },
  filters: {
    typeStr(val) {
      let str = "";
      if (val == 1) {
        str = "FBA运单";
      } else if (val == 2) {
        str = "非FBA运单";
      }
      return str;
    },
    tradeTypeStr(val) {
      let str = "";
      if (val == 1) {
        str = "一般贸易报关";
      } else if (val == 2) {
        str = "不报关";
      }
      return str;
    },
    isDeferredStr(val) {
      let str = "";
      if (val == 1) {
        str = "包税";
      } else if (val == 2) {
        str = "不包税";
      } else {
        str = "自税递延";
      }
      return str;
    },
    isSeparateCustomsClearance(val) {
      let str = "";
      if (val == 1) {
        str = "是";
      } else {
        str = "否";
      }
      return str;
    },
    timeStr(val) {
      let str = "";
      if (val && val > 0) {
        let times = val * 1000;
        str = moment(times).format("YYYY-MM-DD HH:mm:ss");
      }
      return str;
    },
  },
};
</script>

<style lang="less" scoped>
.fd-outer {
  padding: 20px 30px;
}

.ch-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.fd-s1 {
  font-size: 20px;
  color: #fb4e0c;
}

.fd-s2 {
  .s1 {
    font-size: 14px;
    color: #5c5c5c;
  }

  .s2 {
    font-size: 14px;
    color: #9a9a9a;
  }
}

.fd-inner {
  margin-top: 20px;

  .fd-inner-title {
    font-size: 16px;
    font-weight: 600;
  }

  .fd-img1 {
    width: 180px;
    height: 167px;
  }
}

.b-atn {
  cursor: pointer;
}

.fd-address {
  display: flex;
  justify-content: flex-start;

  .d1 {
    .s1 {
      font-size: 12px;
      color: #cccccc;
    }

    .s2 {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
  }

  .d2 {
    margin-top: 13px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.fd-inner-bd {
  span {
    display: inline-block;
    margin-left: 15px;
  }
}
</style>
