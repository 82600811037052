<!--
 * @Author: your name
 * @Date: 2021-11-29 16:20:40
 * @LastEditTime: 2021-12-13 15:06:22
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Waybill/Forecast/index.vue
-->
<template>
  <div class="wb-fore">
    <SearchBar
      :formList="form1"
      @search="search"
      @reset="resetHandle"
    ></SearchBar>
    <div class="btn">
      <a-button type="primary" @click="exportWaybill" v-if="exportShow"
        >导出运单</a-button
      >
      <a-button type="primary" @click="checkInfo" style="margin-left: 15px"
        >查看详情</a-button
      >
      <a-button
        type="primary"
        @click="makeInvoice"
        style="margin-left: 15px"
        v-if="make"
        >制作发票</a-button
      >
      <a-button type="primary" @click="printFun()" style="margin-left: 15px"
        >打印</a-button
      >
      <a-button
        style="margin-left: 15px"
        type="primary"
        @click="copySubNoHandle"
        >复制子单号</a-button
      >
    </div>
    <a-table
      size="small"
      bordered
      :columns="columns"
      :data-source="tableData"
      style="margin-top: 20px"
      :scroll="{ x: 'max-content' }"
      :rowKey="(record, index) => record.id"
      :pagination="pagination"
      @change="handlerPage"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :customRow="customRow"
      :rowClassName="rowClassName"
    >
      <template slot="fbaNo" slot-scope="record">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span>{{ record.fbaNo }}</span>
          <a-popover title="FBA单号" trigger="click">
            <template slot="content">
              <div v-for="(item, index) in fbaList" :key="index">
                {{ item }}
              </div>
            </template>
            <a-button type="link" @click="checkFba(record)">查看</a-button>
          </a-popover>
        </div>
      </template>
      <template slot="waybill_no" slot-scope="record, text">
        <span @click="goDetail(text)" style="color: #1890ff">{{ record }}</span>
      </template>
    </a-table>
    <ly-drawer
      :title="drawer.title"
      :visible.sync="drawer.show"
      :width="drawer.width"
      @cancel="drawer.show = false"
      background="#f8fbff"
    >
      <newDetails
        v-if="drawer.show && drawer.type == 'detail'"
        :id="chooseId"
      ></newDetails>
    </ly-drawer>
    <ly-drawer
      :title="ld.title"
      :visible.sync="ld.show"
      :width="ld.width"
      @cancel="ld.show = false"
    >
      <printWaybill
        :selectedRows="selectedRows"
        :visible="ld.show"
        :id="rowId"
        :billId="chooseRow.id"
        :forecastType="forecastType"
        @num="pwNum"
        v-if="ld.show && ld.index === 1"
      >
      </printWaybill>
      <div slot="footer">
        <a-button-group>
          <a-button @click="ld.show = false"> 取消 </a-button>
          <a-button @click="seePdf">打印箱唛</a-button>
          <a-button :loading="ld.subLoading" type="primary" @click="handlerOk">
            下载为PDF
          </a-button>
        </a-button-group>
      </div>
    </ly-drawer>
  </div>
</template>

<script>
import { exportData } from "@/api/Export";
import { forecastInfo, getFba } from "@/api/Waybill";
import printWaybill from "@/views/Waybill/curd/printWaybill.vue";
import SearchBar from "@/components/SearchBar";
import newDetails from "@/views/Waybill/components/newDetails";
import { copyText } from "@/utils/common";
export default {
  name: "templateWaybill",
  components: {
    printWaybill,
    SearchBar,
    newDetails,
  },
  props: {
    form1: {
      type: Array,
      default: () => [],
    },
    exportShow: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    getDataFun: {
      type: Function,
      default: () => {},
    },
    make: {
      type: Boolean,
      default: false,
    },
    formInlin: {
      type: Object,
      default: () => {},
    },
    exportType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectIndex: null,
      data: [],
      selectedRowKeys: [],
      selectedRows: [],
      // form1,
      // columns: columns,
      drawer2: {
        show: false,
        width: 400,
      },
      pwNum: 0,
      forecastType: 1,

      rowId: "",
      chooseRow: {},
      chooseId: "",
      tableData: [],
      form: {
        page: 1,
        limit: 20,
      },
      drawer: {
        show: false,
        width: 400,
      },
      pagination: {
        current: 1,
        total: 0,
        pageSize: 20,
        showTotal: (total) => `共 ${total} 条`,
        showSizeChanger: true,
        showQuickJumper: true,
      },

      ld: {
        title: "",
        show: false,
        width: "",
        index: -1,
        subLoading: false,
      },
      fbaList: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    checkInfo() {
      if (this.selectedRowKeys.length != 1) {
        this.$message.warning("只能选择一单！");
        return;
      }
      this.chooseId = this.selectedRowKeys[0];
      this.drawer.show = true;
      this.drawer.title = "查看详情";
      this.drawer.width = "1000px";
      this.drawer.type = "detail";
    },
    goDetail(row) {
      this.chooseId = row.id;
      this.drawer.show = true;
      this.drawer.title = "查看详情";
      this.drawer.width = "1000px";
      this.drawer.type = "detail";
    },
    // 点击的样式
    rowClassName(record, index) {
      return index === this.selectIndex ? "row-active" : "";
    },
    // 点击的效果
    customRow(record, index) {
      return {
        on: {
          click: () => {
            this.selectIndex = index;
          },
        },
      };
    },
    checkFba(val) {
      getFba({
        waybillId: val.id,
      }).then((res) => {
        this.fbaList = res.data;
      });
    },
    onSelectChange(val, val2) {
      this.selectedRowKeys = val;
      this.selectedRows = val2 || [];
      this.chooseRow = val2[0] || {};
    },
    exportWaybill() {
      exportData({
        fileName: "导出运单",
        url: "/order/waybill/export",
        params: {
          waybillIds: this.selectedRowKeys,
          status: this.exportType,
        },
        fileType: "xlsx",
      });
    },
    search(val) {
      this.form = Object.assign(this.form, val);
      this.form.page = 1;
      this.getData();
    },
    resetHandle() {
      this.form = {
        page: 1,
        limit: 20,
      };
      this.pagination.current = 1;
      this.search();
    },
    //材积
    handlerOk() {
      this.ld.subLoading = true;
      this.printDialog = true;
      exportData({
        fileName:
          this.chooseRow.waybill_no +
          "+" +
          this.chooseRow.cargoes_num +
          "箱" +
          "+" +
          this.chooseRow.channel_name,
        fileType: "pdf",
        params: {
          waybillIds: this.selectedRows.map((t) => t.id),
        },
        url: "/order/waybill/cargoes/pdf",
      })
        .then(() => {
          this.printDialog = false;
          this.ld.subLoading = false;
        })
        .catch(() => {
          this.printDialog = false;
          this.ld.subLoading = false;
        });
    },
    printFun() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请先选择需要打印的运单！");
        return;
      }
      this.ld.index = 1;
      this.rowId = this.chooseRow.forecastId;
      this.forClick(this.chooseRow);
      this.forecastType = 1;
      this.ld.width = "400px";
      this.ld.title = "打印";
      this.ld.show = true;
    },
    copySubNoHandle() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请先选择需要复制的运单！");
        return;
      }
      if (this.selectedRowKeys.length > 1) {
        this.$message.warning("只能勾选1条运单！");
        return;
      }
      if (!this.selectedRows[0].sub_order_no)
        return this.$message.info("没有可复制的内容!(子单号为空)");
      copyText(this.selectedRows[0].sub_order_no || "");
    },
    forClick(row) {
      forecastInfo({
        forecastId: row.forecastId,
      }).then((res) => {
        this.waybillList = res.data.waybills || [];
      });
    },

    // 查看pdf
    seePdf() {
      let downUrl = process.env.VUE_APP_PDF_URL + `/waybill/cargo/`;
      for (let i = 0; i < this.selectedRows.length; i++) {
        const item = this.selectedRows[i];
        if (i === 0) downUrl += item.uuid;
        else downUrl += `&${item.uuid}`;
      }
      window.open(downUrl);
    },
    handleCancel() {
      this.drawer.show = false;
    },
    // 获取列表数据
    getData() {
      this.getDataFun({ ...this.form, ...this.formInlin }).then((res) => {
        this.tableData = res.data.list;
        this.pagination.total = res.data.total;
      });
    },
    // 查看发票
    viewInvoice(row) {
      this.$router.push({
        path: "/Waybill/_details",
        query: {
          id: row.id,
        },
      });
    },
    // 切换分页
    handlerPage(p) {
      this.form.page = p.current;
      this.form.limit = p.pageSize;
      this.pagination.current = p.current;
      this.pagination.pageSize = p.pageSize;
      this.getData();
    },
    //制作发票
    makeInvoice() {
      if (this.selectedRowKeys.length != 1) {
        this.$message.warning("只能选择一单！");
        return;
      }
      this.$router.push({
        path: "/Waybill/makeAnInvoice",
        query: {
          id: this.selectedRowKeys[0],
          has_invoice: this.chooseRow.has_invoice,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.btn {
  margin-top: 20px;
}

.ant-switch-checked {
  background: #ef6318;
}

.ant-switch {
  background: #ef6318;
}
</style>
