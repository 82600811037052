<!--
 * @Author: your name
 * @Date: 2021-11-22 15:56:04
 * @LastEditTime: 2021-12-03 17:36:28
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Waybill/index.vue
-->
<template>
    <div class="page-container">
        <a-tabs type="card" @change="callback" :default-active-key="getWaybillIndex">
            <a-tab-pane key="3" :tab="'发票'+'('+totalData[2]+')'">
                <templateWaybill :columns="billColumns" :form1="billForm1" :getDataFun="invoiceList" :make="true" :formInlin="{ 'hasInvoice':1}" :exportShow="false"></templateWaybill>
            </a-tab-pane>
            <a-tab-pane key="4" :tab="'下单'+'('+totalData[3]+')'">
                <templateWaybill :columns="placeAnOrderColumns" :form1="placeAnOrderForm1" :make="true" :getDataFun="waybillCreateList" :exportType="0"></templateWaybill>
            </a-tab-pane>
            <a-tab-pane key="5" :tab="'入仓'+'('+totalData[4]+')'">
                <templateWaybill :columns="warehousingColumns" :form1="warehousingForm1" :getDataFun="warehousingList" :exportType="2"></templateWaybill>
            </a-tab-pane>
            <a-tab-pane key="6" :tab="'运输中'+'('+totalData[5]+')'">
                <templateWaybill :columns="inTransitColumns" :form1="inTransitForm1" :getDataFun="shipmentList" :exportType="5"></templateWaybill>
            </a-tab-pane>
            <a-tab-pane key="7" :tab="'签收'+'('+totalData[6]+')'">
                <templateWaybill :columns="receiptColumns" :form1="receiptForm1" :getDataFun="finishList" :exportType="6"></templateWaybill>
            </a-tab-pane>
            <a-tab-pane key="8" :tab="'全部订单'+'('+totalData[7]+')'">
                <templateWaybill :columns="allColumns" :form1="allForm1" :getDataFun="waybillAll" :exportType="12"></templateWaybill>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
    import {
        forecastList,
        invoiceList,
        waybillCreateList,
        warehousingList,
        shipmentList,
        finishList,
        waybillAll
    } from "@/api/Waybill";
    import templateWaybill from "@/views/Waybill/components/templateWaybill"
    import allColumns from "./curd/allColumns";
    import allForm1 from "./curd/allForm1"
    import receiptColumns from "./curd/receiptColumns";
    import receiptForm1 from "./curd/receiptForm1"
    import inTransitColumns from "./curd/inTransitColumns";
    import inTransitForm1 from "./curd/inTransitForm1"
    import warehousingColumns from "./curd/warehousingColumns";
    import warehousingForm1 from "./curd/warehousingForm1"
    import placeAnOrderColumns from "./curd/placeAnOrderColumns";
    import placeAnOrderForm1 from "./curd/PlaceAnOrderForm1"
    import billColumns from "./curd/billColumns";
    import billForm1 from "./curd/billForm1"
    export default {
        name: "Waybill",
        components: {
           templateWaybill
        },
        data() {
            return {
                totalData: [],
                allColumns,
                allForm1,
                waybillAll,
                receiptColumns,
                receiptForm1,
                finishList,
                inTransitColumns,
                inTransitForm1,
                shipmentList,
                warehousingColumns,
                warehousingForm1,
                warehousingList,
                placeAnOrderColumns,
                placeAnOrderForm1,
                waybillCreateList,
                billColumns,
                billForm1,
                invoiceList
            }
        },
        created() {
            let arr = [
                forecastList({
                    page: 1,
                    limit: 20,
                    status: 1
                }),
                forecastList({
                    "page": 1,
                    "limit": 20,
                    "status": 2
                }),
                invoiceList({
                    page: 1,
                    limit: 20,
                    hasInvoice:1
                }),
                waybillCreateList({
                    page: 1,
                    limit: 20,
                }),
                warehousingList({
                    page: 1,
                    limit: 20,
                }),
                shipmentList({
                    page: 1,
                    limit: 20,
                }),
                finishList({
                    page: 1,
                    limit: 20,
                }),

                
            ]
            Promise.all(arr).then(res => {
                for(let i of res){
                    this.totalData.push(i.data.total)
                }
                return waybillAll({
                    page: 1,
                    limit: 20,
                })
            }).then(res1=>{
                this.totalData.push(res1.data.total)
            })
            this.callback(3)
        },

        methods: {
            callback(val) {
                this.setWaybillIndex(val);
            }
        }
    };
</script>

<style lang="less" scoped>
    /deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-disabled {
        color: black !important;
    }
</style>