import { message } from "ant-design-vue";
export const copyText = (text = "") => {
  let aux = document.createElement("textarea");
  aux.value = text;
  document.body.appendChild(aux);
  aux.select();
  if (document.execCommand("copy")) {
    document.execCommand("copy");
    message.success("复制成功!");
  }
  document.body.removeChild(aux);
};
