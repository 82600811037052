export default [
    {
        title: '文件名',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '上传人',
        dataIndex: 'create_user_name',
        key: 'create_user_name',
    },
    {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        scopedSlots: { customRender: 'type' }
    },
    {
        title: '上传时间',
        dataIndex: 'create_at',
        key: 'create_at',
        scopedSlots: { customRender: 'create_at' }
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        scopedSlots: { customRender: 'action' },
    },
]