export default [
    {
        title: '产品英文名',
        dataIndex: 'enItemName',
        key: 'enItemName',
        width: 100
    },
    {
        title: '产品中文名',
        dataIndex: 'zhItemName',
        key: 'zhItemName',
        width: 100
    },
    {
        title: 'Po Number',
        dataIndex: 'po_number',
        key: 'po_number',
    },
    {
        title: '申报单价',
        dataIndex: 'declaredValue',
        key: 'declaredValue',
        width: 100
    },
    {
        title: '申报数量',
        dataIndex: 'num',
        key: 'num',
        width: 100
    },
    {
        title: '申报总价',
        key: 'totalPrice',
        scopedSlots: {
            customRender: 'totalPrice'
        },
        width: 100
    },
    {
        title: '材质英文名',
        dataIndex: 'enMaterial',
        key: 'enMaterial',
        width: 110
    },
    {
        title: '材质中文名',
        dataIndex: 'zhMaterial',
        key: 'zhMaterial',
        width: 110
    },
    {
        title: '海关编码',
        dataIndex: 'hsCode',
        key: 'hsCode',
        width: 160
    },
    {
        title: '用途中文名',
        dataIndex: 'zhPurpose',
        key: 'zhPurpose',
        width: 110
    },
    {
        title: '用途英文名',
        dataIndex: 'enPurpose',
        key: 'enPurpose',
        width: 110
    },
    {
        title: '产品型号',
        dataIndex: 'model',
        key: 'model',
        width: 100
    },
    {
        title: '产品品牌',
        dataIndex: 'brand',
        key: 'brand',
        width: 100
    },
    {
        title: '产品图片',
        key: 'photo',
        width: 130,
        scopedSlots: {
            customRender: 'photo'
        },
    },
    {
        title: '产品销售链接',
        dataIndex: 'amazonUrl',
        key: 'amazonUrl',
        width: 100
    }
]