<template>
  <div class="box" v-if="x == 1">
    <div class="detail_box">
      <div class="title_msg">
        <span>运单号：{{ infoData.waybill_no }}</span>
        <span>金额：{{ infoData.amount }}</span>
        <span></span>
      </div>
      <h4>运单信息</h4>
      <div class="row">
        <div>目的地：{{ infoData.destination }}</div>
        <div>仓库代码：{{ infoData.address.name }}</div>
        <div>邮编：{{ infoData.address.zip_code }}</div>
      </div>
      <div class="row">
        <div>转单号：{{ infoData.transshipNo }}</div>
        <div>快递：{{ infoData.transship_code }}</div>
        <div>
          发票：<a-button type="link" @click="drawer.show = true"
            >查看发票</a-button
          >
        </div>
      </div>
      <div class="row">
        <div>件数：{{ infoData.cargoes_num }}</div>
        <div>申报价值：{{ infoData.declared_value }}</div>
        <div>币种：{{ infoData.currency_name }}</div>
      </div>
      <div class="row">
        <div>品名：{{ infoData.new_product_name }}</div>
        <div>材质：{{ cz.join(",") }}</div>
        <div>
          报关类型：{{ infoData.trade_type === 1 ? "一般报关类型" : "不报关" }}
        </div>
      </div>
      <div class="row">
        <div>收件人：{{ infoData.address.name }}</div>
        <div>收件人电话：{{ infoData.address.phone }}</div>
        <div>收件人邮箱：{{ infoData.address.email }}</div>
      </div>
      <div class="row">
        <div>实重：{{ infoData.real_weight }}</div>
        <div>材积重：{{ infoData.volume_weight }}</div>
        <div>方数：{{ ((infoData.volume * 1) / 100000).toFixed(3) }}</div>
      </div>
      <div class="row">
        <div>结算重：{{ infoData.customer_bill_weight }}</div>
        <div>
          交税方式：{{
            infoData.is_deferred == 1
              ? "包税"
              : infoData.is_deferred == 2
              ? "不包税"
              : "自税递延"
          }}
        </div>
        <div>渠道名称：{{ infoData.channel_name }}</div>
      </div>
      <div class="row">
        <div>备注：{{ infoData.remark }}</div>
        <div>
          查看子单号：<a-button type="link" @click="showChildNoHandle"
            >查看</a-button
          >
        </div>
        <div></div>
      </div>
    </div>
    <div class="volume_box">
      <div class="title_box" style="margin-top: 10px">
        <h4 style="font-size: 16px">材积明细</h4>
      </div>
      <div style="min-height: 150px">
        <a-table
          size="small"
          :columns="drawerColumns"
          :data-source="data"
          :pagination="pagination1"
        >
          <span slot="index" slot-scope="text, xa, index">{{
            xa.total ? xa.total : index + 1
          }}</span>
          <span slot="perimeter" slot-scope="text, xa">{{
            perimeterN(xa)
          }}</span>
        </a-table>
      </div>
    </div>
    <div class="trajectory_box">
      <div class="title_box" style="margin-top: 10px">
        <h4 style="font-size: 16px">运单轨迹</h4>
      </div>
      <div style="text-align: right">
        <a-switch
          checked-children="EN"
          un-checked-children="中文"
          v-model="languageSwitch"
        />
      </div>
      <a-timeline :reverse="false">
        <a-timeline-item
          v-for="(item, index) in LocusData"
          :key="index"
          :color="index == 0 ? 'rgb(239,99,24)' : '#999999'"
        >
          {{ new Date(item.node_time * 1000).toLocaleString() }}
          {{ languageSwitch ? item.name : item.name_zh }}
        </a-timeline-item>
      </a-timeline>
    </div>
    <div class="trajectory_box" style="margin-bottom: 50px">
      <div class="title_box" style="margin-top: 10px">
        <h4 style="font-size: 16px">附件</h4>
        <a-button
          v-if="[1, 2].includes(infoData.status)"
          type="primary"
          @click="uploadAttachmentHandle"
          >上传附件</a-button
        >
      </div>
      <a-table
        size="small"
        :columns="attachmentColumn"
        :data-source="attachmentData"
        :pagination="false"
      >
        <template slot="type" slot-scope="text, xa">
          {{ xa.type === 0 ? "其他" : "报关" }}
        </template>
        <template slot="create_at" slot-scope="text, xa">
          {{ xa.create_at | fltDate }}
        </template>
        <template slot="action" slot-scope="text, xa">
          <a-button type="link" @click="downloadAttachmentHandle(xa)"
            >下载</a-button
          >
          <a-button
            v-if="[1, 2].includes(infoData.status)"
            type="link"
            @click="editAttachmentHandle(xa)"
            >编辑</a-button
          >
          <a-button
            v-if="[1, 2].includes(infoData.status)"
            style="color: red"
            type="link"
            @click="removeHandle(xa)"
            >删除</a-button
          >
        </template>
      </a-table>
    </div>
    <ly-drawer
      :title="drawer.title"
      :visible.sync="drawer.show"
      :width="drawer.width"
      @cancel="drawer.show = false"
      @ok="drawer.show = false"
    >
      <waybillDetails v-if="drawer.show" :id="id"></waybillDetails>
    </ly-drawer>
    <a-modal
      v-model="dialogOpts.visible"
      :title="dialogOpts.title"
      :width="dialogOpts.width"
      :footer="null"
    >
      <component
        :is="dialogOpts.component"
        :pass-data="dialogOpts.passData"
        :type="dialogOpts.type"
        :visible.sync="dialogOpts.visible"
        @submit="dialogSubmitHandle"
      ></component>
    </a-modal>
  </div>
</template>

<script>
import { downloadFileByUrlApi } from "@/api/comm";
import { billInfo } from "@/api/Billing";
import { waybillMilestone, getWaybillAttachment } from "@/api/Waybill";
import waybillDetails from "./waybillDetails/index.vue";
import drawerColumns from "@/views/Waybill/curd/drawerColumns.js";
import attachmentColumn from "@/views/Waybill/curd/attachment.js";
import { waybillCargo } from "@/api/Waybill";
import AttachmentUpload from "./components/attachmentUpload.vue";
import childNo from "./components/childNo.vue";
import { deleteWaybillAttachment } from "@/api/Waybill/index.js";
import FileSaver from "file-saver";
import moment from "moment";
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    waybillDetails,
    AttachmentUpload,
    childNo,
  },
  watch: {
    id: {
      handler(val) {
        billInfo({
          waybillId: val,
        })
          .then((res) => {
            this.infoData = res.data;
            this.cz = this.infoData.new_material_cate_items
              ? this.infoData.new_material_cate_items.map((ele) => ele.name)
              : [];
            return waybillCargo({
              waybillId: val,
            });
          })
          .then((res) => {
            this.data = res.data.cargoes;
            let obj = {
              total: "合计",
              weight: 0,
              volume_weight: 0,
            };
            for (let i of this.data) {
              obj.weight += Number(i.weight);
              obj.volume_weight += Number(i.volume_weight);
            }
            obj.weight = obj.weight.toFixed(2);
            obj.volume_weight = obj.volume_weight.toFixed(2);
            this.data.push(obj);
            return waybillMilestone({
              waybillId: val,
            });
          })
          .then((res) => {
            this.LocusData = res.data;
            this.x = 1;
          });
        this.getAttachmentData();
      },
      immediate: true,
    },
  },
  data() {
    return {
      infoData: {},
      LocusData: [],
      cz: [],
      x: 0,
      drawerColumns,
      attachmentColumn: Object.freeze(attachmentColumn),
      data: [],
      attachmentData: [],
      pagination1: {
        showSizeChanger: true,
      },
      languageSwitch: false,
      drawer: {
        show: false,
        width: "900px",
        title: "查看发票",
      },
      dialogOpts: {
        title: "",
        visible: false,
        component: "AttachmentUpload",
        width: "520px",
        passData: [],
        type: 1,
      },
    };
  },
  filters: {
    fltDate(v) {
      if (!v) return "-";
      return moment(v * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    showChildNoHandle() {
      this.dialogOpts.title = "货箱转单号";
      this.dialogOpts.type = 1;
      this.dialogOpts.width = "720px";
      this.dialogOpts.component = "childNo";
      this.dialogOpts.passData = this.infoData;
      this.dialogOpts.visible = true;
    },
    dialogSubmitHandle() {
      this.dialogOpts.visible = false;
      this.getAttachmentData();
    },
    editAttachmentHandle(row) {
      this.dialogOpts.title = "编辑附件";
      this.dialogOpts.width = "520px";
      this.dialogOpts.component = "AttachmentUpload";
      this.dialogOpts.passData = row;
      this.dialogOpts.type = 2;
      this.dialogOpts.visible = true;
    },
    removeHandle(row) {
      this.$confirm({
        title: "是否删除该附件?",
        content: "",
        onOk: () => {
          deleteWaybillAttachment({ attachmentId: row.id }).then((res) => {
            this.$message.success("操作成功!");
            this.getAttachmentData();
          });
        },
        onCancel() {},
      });
    },
    uploadAttachmentHandle() {
      this.dialogOpts.title = "上传附件";
      this.dialogOpts.width = "520px";
      this.dialogOpts.component = "AttachmentUpload";
      this.dialogOpts.passData = [
        {
          id: this.infoData.id,
        },
      ];
      this.dialogOpts.type = 1;
      this.dialogOpts.visible = true;
    },
    downloadAttachmentHandle(row) {
      const nameStr = row.name.split(",");
      downloadFileByUrlApi({
        file_url: row.url.includes("http")
          ? row.url
          : process.env.VUE_APP_CDN + "/" + row.url,
        file_name: nameStr[0],
      }).then((res) => {
        FileSaver.saveAs(res, row.name);
      });
    },
    getAttachmentData() {
      const params = {
        waybillId: this.id,
      };
      return getWaybillAttachment(params).then((res) => {
        if (res.data) {
          this.attachmentData = res.data;
        }
      });
    },
    perimeterN(row) {
      let arr = [Number(row.length), Number(row.width), Number(row.height)];
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] > arr[j]) {
            var temp = arr[i];
            arr[i] = arr[j];
            arr[j] = temp;
          }
        }
      }
      let x = (arr[1] + arr[0]) * 2 + arr[2];
      return JSON.stringify(x) === "null" ? "——" : x;
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 900px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.title_box {
  margin-top: -20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
}

.volume_box {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  width: 100%;
  box-sizing: content-box;
}

.trajectory_box {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  width: 100%;
  box-sizing: content-box;
}

.detail_box {
  // background: white;
  border-radius: 8px;
  padding: 20px;
  font-size: 16px;
  background: white;

  .title_msg {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #fb4e0c;
    width: 900px;

    span {
      width: 33%;
    }
  }

  .row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 40px;
    line-height: 100%;
    width: 900px;

    div {
      width: 33%;
      font-size: 14px;
    }
  }
}

.btns {
  margin-top: 45px;
  margin-left: 65%;
}

.ant-switch-checked {
  background: #ef6318;
}

.ant-switch {
  background: #ef6318;
}
</style>
