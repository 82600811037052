/*
 * @Author: your name
 * @Date: 2021-11-29 17:02:07
 * @LastEditTime: 2021-12-30 12:02:06
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Waybill/Forecast/curd/index.js
 */
import moment from "moment";
export default [
    {
        title: '运单号',
        dataIndex: 'waybill_no',
        key: 'waybill_no',
        scopedSlots: { customRender: 'waybill_no' }
    },
    {
        title: '渠道名称',
        dataIndex: 'channel_name',
        key: 'channel_name',
    },
    {
        title: '结算重',
        dataIndex: 'bill_weight',
        key: 'bill_weight',
    },
    {
        title: '件数',
        dataIndex: 'cargoes_num',
        key: 'cargoes_num',
    },
    {
        title: '国家或地区',
        dataIndex: 'destination',
        key: 'destination',
    },
    {
        title: '下单时间',
        dataIndex: 'created_at',
        key: 'created_at',
        customRender: (text) => {
            let str = ""
            if (text) {
                str = moment(text * 1000).format("YYYY-MM-DD HH:mm:ss")
            } else if (text === 2) {
                str = "-"
            }
            return str;
        }
    },
    {
        title: '入仓时间',
        dataIndex: 'irikura_time',
        key: 'irikura_time',
        customRender: (text) => {
            let str = ""
            if (text) {
                str = moment(text * 1000).format("YYYY-MM-DD HH:mm:ss")
            } else if (text === 2) {
                str = "-"
            }
            return str;
        }
    },

    {
        title: '材质',
        dataIndex: 'new_material_cate_items',
        key: 'new_material_cate_items',
        customRender: (text) => {
            let str = ''
            if (!text) return
            for (let i of text) {
                str += i.name + ","
            }
            return str;
        }
    },
    {
        title: '品名',
        dataIndex: 'new_product_name',
        key: 'new_product_name',
    },

    {
        title: '备注',
        dataIndex: 'remark',
        key: 'remark',
    },
]