<template functional>
  <div class="track-route">
    <a-timeline>
      <a-timeline-item
        v-for="(item, index) in props.passData"
        :color="index === 0 ? '#ec6429' : ''"
        :key="index"
      >
        <span style="margin-right: 16px">{{ item.route_time | fltDate }}</span>
        <span>{{ item.oplog_info }} </span>
      </a-timeline-item>
    </a-timeline>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  props: {
    passData: {
      type: Array,
      default: () => [],
    },
  },
  filters: {
    fltDate(v) {
      if (v) return dayjs(v * 1000).format("YYYY-MM-DD HH:mm:ss");
      else return "-";
    },
  },
};
</script>
<style lang="less" scoped></style>
