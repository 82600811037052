var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-outer"},_vm._l((_vm.list),function(item2,index2){return _c('div',{key:index2,staticStyle:{"border-top":"1px solid #fff","width":"280px"},attrs:{"id":item2.key}},_vm._l((item2.value),function(item,index){return _c('div',{key:index,staticClass:"pc-container"},[_c('div',{staticClass:"pc-d1",staticStyle:{"padding":"5px"}},[_vm._v(" "+_vm._s(item.no)+" ")]),_c('div',{staticClass:"pc-d2"},[_c('div',{staticClass:"pc-d2-dd1"},[_c('div',{staticClass:"pdd-s1"},[_vm._v(" "+_vm._s(item.countryName)+" ")])]),_c('div',{staticClass:"pc-d2-dd2"},[_c('div',{staticClass:"pdd-s2"},[_vm._v(" "+_vm._s(item.num)+" ")]),_c('div',{staticClass:"pdd-s3"},[_vm._v(" "+_vm._s(item.cargoesNum)+" ")]),_c('div',{staticClass:"pdd-line"})])]),_c('div',{staticClass:"pc-d1-d1"},[_c('div',{staticClass:"pc-d1-d1-in",style:({
            'font-size': item.channelName.length > 13 ? '18px' : '24px',
          })},[_vm._v(" "+_vm._s(item.channelName)+" ")])]),_c('div',{staticClass:"pc-d1-d1",staticStyle:{"height":"65px"}},[_c('div',{staticClass:"pc-d1-d1-in-remark",style:({
            'font-size': _vm.calcSize(item.remark.length),
            'align-items': item.remark.length >= 66 ? 'none' : 'center',
          })},[_vm._v(" "+_vm._s(item.remark)+" ")])]),_c('div',{staticClass:"pc-d3"},[_c('div',{directives:[{name:"qrcode",rawName:"v-qrcode",value:({
            no: _vm.createBoxNo(item.no, item.num),
            width: '100',
            height: '100',
          }),expression:"{\n            no: createBoxNo(item.no, item.num),\n            width: '100',\n            height: '100',\n          }"}],staticStyle:{"margin-top":"8px"}})]),_c('div',{staticStyle:{"font-weight":"600","text-align":"center","font-size":"18px"}},[_vm._v(" "+_vm._s(_vm.createBoxNo(item.no, item.num))+" ")])])}),0)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }