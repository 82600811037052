export default [
    {
        type: 'textarea',
        label: "运单号",
        prop: 'waybillNo'
    },
    {
        type: 'input',
        label: "国家或地区",
        prop: 'countryName'
    },
    {
        type: 'date',
        label: "下单时间",
        prop: 'createdAt'
    },
    {
        type: 'input',
        label: "渠道",
        prop: 'channelName'
    },
    {
        type: 'input',
        label: "备注",
        prop: 'remark'
    },
    {
        type: 'input',
        label: '仓库代码',
        prop: 'destination'
    }
]