<!--
 * @Author: your name
 * @Date: 2021-12-03 14:48:43
 * @LastEditTime: 2022-01-11 11:07:34
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Waybill/Bill/curd/make.vue
-->
<template>
  <a-form-model
    ref="ruleForm1"
    :model="form"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-form-model-item label="VAT税号" prop="vat">
      <a-input v-model="form.vat" allowClear placeholder="VAT税号" />
    </a-form-model-item>
    <a-form-model-item
      label="报关类型"
      prop="tradeType"
      :rules="validate({ name: 'value', msg: '请选择贸易类型' })"
    >
      <a-radio-group v-model="form.tradeType">
        <a-radio :value="1"> 一般贸易报关 </a-radio>
        <a-radio :value="2"> 不报关 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item
      label="交税方式"
      prop="isDeferred"
      :rules="validate({ name: 'value', msg: '请选择递延' })"
    >
      <a-radio-group v-model="form.isDeferred">
        <a-radio :value="1"> 包税 </a-radio>
        <a-radio :value="2"> 不包税 </a-radio>
        <a-radio :value="3"> 自税递延 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item
      label="单独清关"
      prop="isSeparateCustomsClearance"
      :rules="validate({ name: 'value', msg: '请选择单独清关' })"
    >
      <a-radio-group v-model="form.isSeparateCustomsClearance">
        <a-radio :value="0"> 否 </a-radio>
        <a-radio :value="1"> 是 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item label="Amazon Reference ID" prop="amazonReferenceId">
      <a-input v-model="form.amazonReferenceId" />
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { saveInvoice, makeInvoice, invoiceInfo } from "@/api/Waybill";
export default {
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      form: {
        waybillId: "",
        vat: "",
        tradeType: 2,
        isDeferred: 0,
        isSeparateCustomsClearance: 1,
        amazonReferenceId: "",
      },
    };
  },
  created() {
    this.form.waybillId = this.id * 1;
    this.getDetails();
  },
  methods: {
    // 获取发票详情
    getDetails() {
      invoiceInfo({ waybillId: this.form.waybillId }).then((res) => {
        let obj = {
          waybillId: res.data.waybill_id,
          tradeType: res.data.trade_type || 2,
          isDeferred: res.data.is_deferred,
          isSeparateCustomsClearance: res.data.is_separate_customs_clearance,
          amazonReferenceId: res.data.amazon_reference_id,
          vat: res.data.vat,
        };
        this.form = Object.assign(this.form, obj);
      });
    },
    // 保存
    save() {
      this.$refs.ruleForm1.validate((valid) => {
        if (valid) {
          saveInvoice(this.form)
            .then(() => {
              this.$message.success("发票保存成功");
              this.$emit("success");
            })
            .catch(() => {
              this.$emit("fail");
            });
        } else {
          this.$emit("fail");
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 提交的方法
    onSubmit() {
      this.$refs.ruleForm1.validate((valid) => {
        if (valid) {
          makeInvoice(this.form)
            .then(() => {
              this.$message.success("制作发票成功");
              this.$emit("success");
            })
            .catch(() => {
              this.$emit("fail");
            });
        } else {
          this.$emit("fail");
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
