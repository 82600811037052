<template>
    <a-form-model ref="ruleForm" :model="formData" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-model-item v-if="type === 1" label="附件" prop="fileList" :rules="[{ required: true, message: '请上传附件!' }]">
            <upload-file :key="uploadFileKey" ref="uploadFileRef" :value.sync="formData.fileList"></upload-file>
        </a-form-model-item>
        <a-form-model-item v-if="type === 2" label="附件名称" prop="name" :rules="[{ required: true, message: '请填写附件名称!' }]">
            <a-input v-model="formData.name" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="类型" prop="type" :rules="[{ required: true, message: '请选择类型!' }]">
            <a-select v-model="formData.type" placeholder="请选择">
                <a-select-option :value="0">
                    其他
                </a-select-option>
                <a-select-option :value="1">
                    报关
                </a-select-option>
            </a-select>
        </a-form-model-item>
        <a-form-model-item style="text-align: right" :wrapper-col="{ span: 24 }">
            <a-button @click="cancelHandle">
                取消
            </a-button>
            <a-button style="margin-left: 10px;" type="primary" @click="submitHandle">
                确认
            </a-button>
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import UploadFile from '@/components/uploadFile/index.vue';
import { addWaybillAttachment, editWaybillAttachment } from '@/api/Waybill/index.js';
export default {
    props: {
        visible: {
            type: Boolean,
            default: () => false
        },
        passData: {
            type: [Array, Object],
            default: () => ({})
        },
        type: {
            type: [Number, String],
            default: () => 1
        }
    },
    components: {
        UploadFile
    },
    data() {
        return {
            formData: {
                fileList: [],
                type: 0,
                name: ''
            },
            uploadFileKey: 0
        }
    },
    created() {
        if (this.type === 2) {
            this.formData.name = this.passData.name;
            this.formData.type = this.passData.type;
        }
    },
    watch: {
        visible(v) {
            if (v) {
                if (this.type === 2) {
                    this.formData.name = this.passData.name;
                    this.formData.type = this.passData.type;
                }
            } else {
                this.formData = {
                    fileList: [],
                    type: 0,
                    name: ''
                }
            }
        }
    },
    methods: {
        submitHandle() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    if (this.type === 1) {
                        const params = {
                            data: []
                        }
                        params.data = this.passData.map(t => {
                            return {
                                waybillId: t.id,
                                fileItems: this.formData.fileList.map(f => {
                                    return {
                                        ...f,
                                        type: this.formData.type
                                    }
                                })
                            }
                        })
                        addWaybillAttachment(params).then(() => {
                            this.$message.success('操作成功!')
                            this.uploadFileKey++;
                            this.$emit('submit')
                        })
                    } else { //编辑
                        const { name } = this.formData;
                        const params = {
                            attachmentId: this.passData.id,
                            name,
                            type: this.formData.type,
                            url: this.passData.url,
                        }
                        editWaybillAttachment(params).then(() => {
                            this.$message.success('操作成功!');
                            this.uploadFileKey++;
                            this.$emit('submit')
                        })
                    }
                } else {
                    return false;
                }
            });
        },
        cancelHandle() {
            this.$refs.ruleForm.resetFields();
            this.uploadFileKey++;
            this.$emit('update:visible', false)
        }
    }
}

</script>
<style lang='less' scoped></style>
