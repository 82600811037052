<template>
    <div>
        <a-row>
            <a-col style="margin:0px 0px 6px 28px">
                <span >运单号：<span>{{reqData.waybill_no}}</span></span>
            </a-col>
        </a-row>
        <a-row>
            <a-col>
                <span>修改前数量：<span>{{reqData.cargoes_num}}</span></span>
            </a-col>
        </a-row>
        <a-row>
            <a-form-model :model="form" ref="edForm">
                <a-form-model-item label="货件数量" prop="num" style="margin-left:3px" :rules="validate({ name: 'value', msg: '请输入货件数量' })">
                    <a-input allowClear style="width:100%" placeholder="请输入" v-model="form.num"></a-input>
                </a-form-model-item>
            </a-form-model>
        </a-row>
    </div>
</template>

<script> 
    import { waybillEdit } from "@/api/Waybill";
    export default {
        props: {
            infoData: {
                type: Object,
                default: () => { },
            }
        },

        data() {
            return {
                reqData: "",
                form: {
                    num: '',
                    waybillId: '',
                }
            }
        },
        watch: {
            infoData: {
                handler(val) {
                    this.reqData = val
                    console.log(this.reqData);
                },
                immediate: true
            }
        },
        methods: {
            submit() {
                return new Promise((resolve, reject) => {
                    this.$refs.edForm.validate(valid => {
                        if (valid) {
                            this.form.num = Number(this.form.num)
                            this.form.waybillId =  this.reqData.id
                            waybillEdit(this.form).then(() => {
                                this.$message.success("修改成功")
                                resolve(true)
                            }).catch(() => {
                                reject(false)
                            })
                        }
                    })
                })
            }
        }
    }
</script>

<style lang="less" scoped>
</style>