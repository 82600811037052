/*
 * @Author: your name
 * @Date: 2021-11-29 17:02:07
 * @LastEditTime: 2021-12-03 16:41:31
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Waybill/Forecast/curd/index.js
 */
import moment from "moment";
export default [
  {
    title: "FBA运单",
    key: "fbaNo",
    scopedSlots: { customRender: "fbaNo" },
  },
  {
    title: "运单号",
    dataIndex: "waybill_no",
    key: "waybill_no",
    scopedSlots: { customRender: "waybill_no" },
  },
  {
    title: "目的地",
    dataIndex: "destination",
    key: "destination",
  },
  {
    title: "转单号",
    dataIndex: "transship_no",
    key: "transship_no",
  },
  {
    title: "仓库代码",
    dataIndex: "ware_house_code",
    key: "ware_house_code",
  },
  {
    title: "渠道",
    dataIndex: "channel_name",
    key: "channel_name",
  },
  {
    title: "件数",
    dataIndex: "cargoes_num",
    key: "cargoes_num",
  },
  {
    title: "结算重",
    dataIndex: "bill_weight",
    key: "bill_weight",
  },
  {
    title: "材质",
    dataIndex: "new_material_cate_items",
    key: "new_material_cate_items",
    customRender: (text) => {
      let str = "";
      if (!text) return;
      for (let i of text) {
        str += i.name + ",";
      }
      return str;
    },
  },
  {
    title: "品名",
    dataIndex: "new_product_name",
    key: "new_product_name",
  },
  {
    title: "入仓时间",
    dataIndex: "irikura_time",
    key: "irikura_time",
    customRender: (text) => {
      let str = "";
      if (text) {
        str = moment(text * 1000).format("YYYY-MM-DD HH:mm:ss");
      } else if (text === 2) {
        str = "-";
      }
      return str;
    },
  },
  {
    title: "快递公司",
    dataIndex: "transship_code",
    key: "transship_code",
  },

  {
    title: "最新轨迹",
    dataIndex: "milestone",
    key: "milestone",
  },
  {
    title: "轨迹更新时间",
    dataIndex: "mileStoneTime",
    key: "mileStoneTime",
    customRender: (text) => {
      let str = "";
      if (text) {
        str = moment(text * 1000).format("YYYY-MM-DD HH:mm:ss");
      } else if (text === 2) {
        str = "-";
      }
      return str;
    },
  },
  {
    title: "下单时间",
    dataIndex: "created_at",
    key: "created_at",
    customRender: (text) => {
      let str = "";
      if (text) {
        str = moment(text * 1000).format("YYYY-MM-DD HH:mm:ss");
      } else if (text === 2) {
        str = "-";
      }
      return str;
    },
  },
  {
    title: "子单号",
    dataIndex: "sub_order_no",
    key: "sub_order_no",
    width: 120,
    ellipsis: true,
  },
  {
    title: "备注",
    dataIndex: "remark",
    key: "remark",
  },
];
