export default [
    {
      title: '序号',
      dataIndex: 'index',
      key:'index',
      scopedSlots: { customRender: 'index' },
    },
    {
      title: '总实重',
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: '长',
      dataIndex: 'length',
      key: 'length',
    },
    {
      title: '宽',
      dataIndex: 'width',
      key: 'width',
    },
    {
      title: '高',
      dataIndex: 'height',
      key: 'height',
    },
    {
      title: '材积重',
      dataIndex: 'volume_weight',
      key: 'volume_weight',
    },
    {
      title: '周长',
      dataIndex: 'perimeter',
      key: 'perimeter',
      scopedSlots: { customRender: 'perimeter' },

    },
]