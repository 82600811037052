<template>
    <a-upload name="file" :multiple="true" :action="action" :headers="headers" @change="handleChange"
        :remove="removeFileHandle">
        <a-button> <a-icon type="upload" /> 点击上传 </a-button>
    </a-upload>
</template>

<script>
import store from "@/store";
export default {
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            action: process.env.VUE_APP_API_URL + "/file/upload/file",
            headers: {
                "ansuex-user-token": store.getters.getToken,
                "device-type": "web",
            },
        }
    },
    methods: {
        // 上传成功
        handleChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                this.$emit('update:value', info.fileList.map(t => {
                    return {
                        name: t.name,
                        fileUrl: t.response.data.path
                    }
                }))
            } else if (info.file.status === 'error') {
                this.$message.error(`${info.file.name} file upload failed.`);
            }
        },
        removeFileHandle(file) {
            const fileUrl = file.response.data.path;
            const arr = _.cloneDeep(this.value);
            this.$emit('update:value', arr.filter(t => t.fileUrl !== fileUrl));
        },
        // 上传失败
        handlerError() { },
        // 删除文件的钩子
        handlerRemove(file, fileList) {
            this.fileList = fileList || [];
            this.$emit("change", this.fileList)
        }
    },
}

</script>
<style lang='scss' scoped></style>
