<template>
  <div class="child-no">
    <a-table
      bordered
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
    >
      <template v-slot:index="text, record, index">
        {{ index + 1 }}
      </template>
      <template v-slot:action="scoped">
        <a-button type="link" @click="showTrackRouteInfo(scoped)"
          >查看轨迹</a-button
        >
      </template>
    </a-table>
    <a-modal
      v-model="dialogOpts.visible"
      :title="dialogOpts.title"
      :width="dialogOpts.width"
      :footer="null"
    >
      <component
        :is="dialogOpts.component"
        :pass-data="dialogOpts.passData"
        :type="dialogOpts.type"
        :visible.sync="dialogOpts.visible"
      ></component>
    </a-modal>
  </div>
</template>

<script>
import { getChildNoInfoApi } from "@/api/Waybill/index";
import trackRoute from "./trackRoute.vue";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    passData: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    trackRoute,
  },
  data() {
    return {
      columns: Object.freeze([
        {
          title: "序号",
          key: "index",
          width: 70,
          scopedSlots: { customRender: "index" },
        },
        {
          title: "货箱号",
          dataIndex: "box_number",
          width: 160,
        },
        {
          title: "转单号",
          dataIndex: "transfer_no",
          width: 160,
        },
        {
          title: "快递公司",
          dataIndex: "fast_mail",
          width: 120,
        },
        {
          title: "路由",
          key: "action",
          width: 90,
          scopedSlots: { customRender: "action" },
        },
      ]),
      tableData: [],
      dialogOpts: {
        title: "",
        width: "520px",
        component: "",
        type: 1,
        passData: {},
        visible: false,
      },
    };
  },
  created() {
    this.getData();
  },
  watch: {
    visible(v) {
      if (v) this.getData();
    },
  },
  methods: {
    getData() {
      return getChildNoInfoApi({ waybillId: this.passData.id }).then((res) => {
        this.tableData = res.data || [];
      });
    },
    showTrackRouteInfo(row) {
      this.dialogOpts.title = "查看轨迹";
      this.dialogOpts.width = "520px";
      this.dialogOpts.passData = row.router_info || [];
      this.dialogOpts.component = "trackRoute";
      this.dialogOpts.visible = true;
    },
  },
};
</script>
<style lang="less" scoped></style>
